import './FractalContactSection.scss';

import React, { useState, FC } from 'react';
import { Trans } from '@lingui/macro';
import { useForm } from 'react-hook-form';
import superagent from 'superagent';
import classNames from 'classnames';
import { load } from 'recaptcha-v3';
import emailValidationRegex from 'utils/emailValidationRegex';
import { FractalJoinProps } from '../FractalJoin';

type EmailDeliveryRequest = {
  token?: string;
};

export const FractalContactForm: FC<FractalJoinProps> = ({ page }) => {
  const emailEndPoint = 'https://europe-west3-yanchware.cloudfunctions.net/yanchwareContacts';

  const [submitSuccessState, setSubmitSuccessState] = useState(false);
  const [submitFailedState, setSubmitFailedState] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const onSubmit = async (emailDeliveryRequest: EmailDeliveryRequest) => {
    setSubmitButtonDisabled(true);

    const recaptcha = await load('6LeoaccZAAAAAH5_dOmimvOwM3rF62dh25UPKd6Z');
    const token = await recaptcha.execute('submit');
    emailDeliveryRequest.token = token;
    if (window) {
      return superagent
        .post(emailEndPoint)
        .send(emailDeliveryRequest)
        .set('Content-Type', 'application/json')
        .then((response) => {
          if (response.status === 200) {
            setSubmitSuccessState(true);
          }
        })
        .catch((err) => {
          setSubmitFailedState(true);
        });
    }
  };

  if (submitSuccessState) {
    return (
      <div className="contact-section__form">
        <div className="contact-section__form-sent-successfully tac">
          <h3>
            <Trans>Thank you for reaching out!</Trans>
          </h3>
          <p>
            <Trans>You will get a response as soon as possible!</Trans>
          </p>
        </div>
      </div>
    );
  }
  const isRequired = true;
  return (
    <form noValidate className="contact-section__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="contact-section__row">
        <label className="contact-section__label" htmlFor="cf-name">
          <Trans>Name*</Trans>
        </label>
        <input
          id="cf-name"
          className={classNames('contact-section__input', {
            'contact-section__input--error': errors.name,
          })}
          type="text"
          aria-invalid={errors.name ? 'true' : 'false'}
          {...register('name', {
            required: {
              value: isRequired,
              message: 'Name is required.',
            },
          })}
        />
        <div
          role="alert"
          className={classNames('contact-section__input-error', {
            'contact-section__input-error--show': errors.name,
          })}
        >
          {errors.name?.message}
        </div>
      </div>
      <div className="contact-section__row">
        <label className="contact-section__label" htmlFor="cf-email">
          <Trans>E-mail*</Trans>
        </label>
        <input
          id="cf-email"
          className={classNames('contact-section__input', {
            'contact-section__input--error': errors.email,
          })}
          type="email"
          aria-invalid={errors.email ? 'true' : 'false'}
          {...register('email', {
            pattern: {
              value: emailValidationRegex,
              message: 'Email is invalid.',
            },
            required: {
              value: isRequired,
              message: 'Email is required.',
            },
          })}
        />
        <div
          role="alert"
          className={classNames('contact-section__input-error', {
            'contact-section__input-error--show': errors.email,
          })}
        >
          {errors.email?.message}
        </div>
      </div>
      {page === 'enterprise' && (
        <>
          <div className="contact-section__row">
            <label className="contact-section__label" htmlFor="cf-s-enterprise">
              <Trans>Enterprise Name*</Trans>
            </label>
            <input
              id="cf-s-enterprise"
              className={classNames('contact-section__input', {
                'contact-section__input--error': errors.enterprise,
              })}
              type="text"
              aria-invalid={errors.enterprise ? 'true' : 'false'}
              {...register('enterprise', {
                required: {
                  value: isRequired,
                  message: 'Enterprise name is required.',
                },
              })}
            />
            <div
              role="alert"
              className={classNames('contact-section__input-error', {
                'contact-section__input-error--show': errors.enterprise,
              })}
            >
              {errors.enterprise?.message}
            </div>
          </div>

          <div className="contact-section__row">
            <label className="contact-section__label" htmlFor="cf-s-phone">
              <Trans>Phone Number*</Trans>
            </label>
            <input
              id="cf-s-phone"
              className={classNames('contact-section__input', {
                'contact-section__input--error': errors.phone,
              })}
              type="text"
              aria-invalid={errors.phone ? 'true' : 'false'}
              {...register('phone', {
                required: {
                  value: isRequired,
                  message: 'Phone number is required.',
                },
              })}
            />
            <div
              role="alert"
              className={classNames('contact-section__input-error', {
                'contact-section__input-error--show': errors.phone,
              })}
            >
              {errors.phone?.message}
            </div>
          </div>

          <div className="contact-section__row">
            <label className="contact-section__label" htmlFor="cf-s-time">
              <Trans>Date and time you wish to be called back*</Trans>
            </label>
            <input
              id="cf-s-time"
              type="datetime-local"
              className={classNames(
                'contact-section__input',
                'contact-section__input--datepicker',
                {
                  'contact-section__input--error': errors.time,
                }
              )}
              aria-invalid={errors.time ? 'true' : 'false'}
              {...register('time', {
                required: {
                  value: isRequired,
                  message: 'Date and time are required.',
                },
              })}
            />
            <div
              role="alert"
              className={classNames('contact-section__input-error', {
                'contact-section__input-error--show': errors.time,
              })}
            >
              {errors.time?.message}
            </div>
          </div>
        </>
      )}
      <input value={page} {...register('project', {})} type="hidden" />

      <input
        disabled={submitButtonDisabled}
        type="submit"
        value={submitButtonDisabled ? 'Loading...' : 'Enroll now'}
        className="contact-section__button"
      />

      {submitFailedState && (
        <div className="contact-section__form-error">
          <Trans>
            Oh no! Something went wrong! <br />
            Please refresh the page and try again.
          </Trans>
        </div>
      )}
    </form>
  );
};

const FractalContactSection: FC<FractalJoinProps> = ({ content, page }) => {
  if (!content) {
    return null;
  }
  const { title, text, contactEmail } = content;
  return (
    <>
      <div className="contact-section__info">
        <h2 className="contact-section__title">{title}</h2>
        <p className="contact-section__text">{text}</p>
        <p className="contact-section__email">{contactEmail}</p>
      </div>
      <div className="contact-section__content">
        <FractalContactForm page={page} />
      </div>
    </>
  );
};

export default FractalContactSection;
