import { Trans } from '@lingui/macro';
import Image from 'components/atoms/Image/Image';
import React, { FC } from 'react';
import './../../scss/components/CompareSolution.scss';
import { CompareSolutionSectionProps } from './CompareSolutionSection.model';

export const CompareSolutionSection: FC<CompareSolutionSectionProps> = ({
  id,
  title,
  subtitle,
  isPulumiVisible = true,
  isCompareNameVisible = true,
}) => {
  return (
    <section className="mt-40 comparison" id={id}>
      <div className="text-center mb-6">
        <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
          {title}
        </h2>
        {subtitle && (
          <div className="flex justify-center">
            <p className="text-[16px] md:text-[24px] font-[500] text-base-300 leading-[175%] max-w-[43rem]">
              {subtitle}
            </p>
          </div>
        )}
      </div>

      <div className="rounded-lg shadow-testimonials comparison-table">
        <div className="rounded-t-lg bg-base-100 comparison-table__header">
          <div className="comparison-table__empty-space"></div>
          <div className="comparison-table__header-row">
            <div className="comparison-table__header-item">
              <Image
                className="comparison-table__header-item-image"
                name="companies/terraform-logo"
              />
              {isCompareNameVisible && (
                <div className="comparison-table__header-item-name">
                  <Trans>Terraform</Trans>
                </div>
              )}
            </div>
            {isPulumiVisible && (
              <div className="comparison-table__header-item">
                <Image
                  className="comparison-table__header-item-image"
                  name="companies/pulumi-logo"
                />
                {isCompareNameVisible && (
                  <div className="comparison-table__header-item-name">
                    <Trans>Pulumi</Trans>
                  </div>
                )}
              </div>
            )}
            <div className="comparison-table__header-item">
              <Image
                className="comparison-table__header-item-image"
                name="companies/fractal-logo"
              />
              {isCompareNameVisible && (
                <div className="comparison-table__header-item-name">
                  <Trans>Fractal</Trans>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="pl-8 md:pl-0 comparison-table__row">
          <span className="comparison-table__row-title">
            <Trans>Support for multiple clouds</Trans>
          </span>
          <div className="comparison-table__row-checks">
            <Image className="comparison-table__row-image" name="check-mark-grey" />
            {isPulumiVisible && (
              <Image className="comparison-table__row-image" name="check-mark-grey" />
            )}
            <Image className="comparison-table__row-image" name="check-mark-grey" />
          </div>
        </div>

        <div className="pl-8 md:pl-0 comparison-table__row">
          <span className="comparison-table__row-title">
            <Trans>No Domain Specific Language</Trans>
          </span>
          <div className="comparison-table__row-checks">
            <Image className="comparison-table__row-image" name="cancel-grey" />
            {isPulumiVisible && (
              <Image className="comparison-table__row-image" name="check-mark-grey" />
            )}
            <Image className="comparison-table__row-image" name="check-mark-grey" />
          </div>
        </div>

        <div className="pl-8 md:pl-0 comparison-table__row">
          <span className="comparison-table__row-title">
            <Trans>No specialized Cloud Infrastrure Staff required</Trans>
          </span>
          <div className="comparison-table__row-checks">
            <Image className="comparison-table__row-image" name="cancel-grey" />
            {isPulumiVisible && (
              <Image className="comparison-table__row-image" name="cancel-grey" />
            )}
            <Image className="comparison-table__row-image" name="check-mark-grey" />
          </div>
        </div>

        <div className="pl-8 md:pl-0 comparison-table__row">
          <span className="comparison-table__row-title">
            <Trans>Multi-cloud Templates</Trans>
          </span>
          <div className="comparison-table__row-checks">
            <Image className="comparison-table__row-image" name="cancel-grey" />
            {isPulumiVisible && (
              <Image className="comparison-table__row-image" name="cancel-grey" />
            )}
            <Image className="comparison-table__row-image" name="check-mark-grey" />
          </div>
        </div>

        <div className="pl-8 md:pl-0 comparison-table__row">
          <span className="comparison-table__row-title">
            <Trans>Hardened Components</Trans>
          </span>
          <div className="comparison-table__row-checks">
            <Image className="comparison-table__row-image" name="cancel-grey" />
            {isPulumiVisible && (
              <Image className="comparison-table__row-image" name="cancel-grey" />
            )}
            <Image className="comparison-table__row-image" name="check-mark-grey" />
          </div>
        </div>

        <div className="pl-8 md:pl-0 comparison-table__row">
          <span className="comparison-table__row-title">
            <Trans>Build and expose custom infrastructure operations</Trans>
          </span>
          <div className="comparison-table__row-checks">
            <Image className="comparison-table__row-image" name="cancel-grey" />
            {isPulumiVisible && (
              <Image className="comparison-table__row-image" name="cancel-grey" />
            )}
            <Image className="comparison-table__row-image" name="check-mark-grey" />
          </div>
        </div>

        <div className="pl-8 md:pl-0 comparison-table__row">
          <span className="comparison-table__row-title">
            <Trans>Automated components update</Trans>
          </span>
          <div className="comparison-table__row-checks">
            <Image className="comparison-table__row-image" name="cancel-grey" />
            {isPulumiVisible && (
              <Image className="comparison-table__row-image" name="cancel-grey" />
            )}
            <Image className="comparison-table__row-image" name="check-mark-grey" />
          </div>
        </div>

        {!isPulumiVisible && (
          <div className="pl-8 md:pl-0 comparison-table__row">
            <span className="comparison-table__row-title">
              <Trans>Enterprise ready</Trans>
            </span>
            <div className="comparison-table__row-checks">
              <Image className="comparison-table__row-image" name="cancel-grey" />
              <Image className="comparison-table__row-image" name="check-mark-grey" />
            </div>
          </div>
        )}

        <div className="pl-8 md:pl-0 comparison-table__row">
          <span className="comparison-table__row-title">
            <Trans>Multi-cloud Native</Trans>
          </span>
          <div className="comparison-table__row-checks">
            <Image className="comparison-table__row-image" name="cancel-grey" />
            {isPulumiVisible && (
              <Image className="comparison-table__row-image" name="cancel-grey" />
            )}
            <Image className="comparison-table__row-image" name="check-mark-grey" />
          </div>
        </div>
      </div>
    </section>
  );
};
