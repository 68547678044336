import './FractalJoin.scss';
import React, { FC } from 'react';
import Image from '../../atoms/Image/Image';
import FractalContactSection from './FractalContactSection/FractalContactSection';

type MessageContent = {
  title?: string;
  text?: string;
  contactEmail?: string;
};

export type FractalJoinProps = {
  page: 'crowd' | 'enterprise';
  content?: MessageContent;
  isVisible?: boolean;
};

const FractalJoin: FC<FractalJoinProps> = ({ page, isVisible }) => {
  return (
    <>
      {(!isVisible && <span />) || (
        <div className="content-blocks content-blocks--image-background container">
          <div className="content-block">
            <Image className="fractal-join__image" name="fractal-page/fractal-join" />
            <div className="content-block__info">
              <FractalContactSection
                content={{
                  title:
                    page === 'crowd' ? 'Register as a single user' : 'Register your enterprise',
                }}
                page={page}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default FractalJoin;
