// pricingCardsData.ts

export type FeatureContent = {
  [key: string]: boolean;
};

export type FeatureListItem = {
  header?: string;
  content: FeatureContent[];
};

export type PricingCardData = FeatureListItem[][];

export const featureLists: PricingCardData = [
  [
    {
      content: [
        { '5 Fractals included': true },
        { '2 Livesystems included': true },
        { '1 agent included': true },
        { '1 user included': true },
        { 'Private Livesystems': true },
      ],
    },
    {
      header: 'Fractal Cloud',
      content: [
        { 'SDK support': true },
        { 'Public Livesystems': false },
        { 'Private Fractals': false },
        { 'Organization support': false },
      ],
    },
    {
      header: 'Security',
      content: [
        { MFA: true },
        { 'Session tracking': true },
        { 'Public Personal Identity Providers': true },
        { 'Public Business Identity Providers': false },
        { RBAC: false },
        { 'On-Prem Identity providers': false },
      ],
    },
    {
      header: 'Collaboration',
      content: [{ History: false }, { 'Change Reviews': false }, { 'Issue tracking': false }],
    },
    {
      header: 'Advanced Features',
      content: [
        { 'Possibility of On-Prem dedicated setup': false },
        { 'Cloud Scan': false },
        { 'Cost Analysis': false },
        { 'Cost Predictions and Advice': false },
        { 'Cost Reduction': false },
      ],
    },
  ],
  [
    {
      content: [
        { '10 Fractals included': true },
        { '5 Livesystems included': true },
        { '2 agents included': true },
        { '1+ users included': true },
        { 'Private Livesystems': true },
      ],
    },
    {
      header: 'Fractal Cloud',
      content: [
        { 'SDK support': true },
        { 'Public Livesystems': true },
        { 'Private Fractals': true },
        { 'Organization support': true },
      ],
    },
    {
      header: 'Security',
      content: [
        { MFA: true },
        { 'Session tracking': true },
        { 'Public Personal Identity Providers': true },
        { 'Public Business Identity Providers': true },
        { 'Basic RBAC': true },
        { 'On-Prem Identity providers': false },
      ],
    },
    {
      header: 'Collaboration',
      content: [
        { 'Limited History': true },
        { 'Change Reviews': false },
        { 'Issue tracking': false },
      ],
    },
    {
      header: 'Advanced Features',
      content: [
        { 'Possibility of On-Prem dedicated setup': false },
        { 'Cloud Scan': false },
        { 'Cost Analysis': false },
        { 'Cost Predictions and Advice': false },
        { 'Cost Reduction': false },
      ],
    },
  ],
  [
    {
      content: [
        { 'Unlimited Fractals included': true },
        { 'Unlimited Livesystems included': true },
        { 'Unlimited agents included': true },
        { 'Unlimited users included': true },
        { 'Private Livesystems': true },
      ],
    },
    {
      header: 'Fractal Cloud',
      content: [
        { 'SDK support': true },
        { 'Public Livesystems': true },
        { 'Private Fractals': true },
        { 'Organization support': true },
      ],
    },
    {
      header: 'Security',
      content: [
        { MFA: true },
        { 'Session tracking': true },
        { 'Public Personal Identity Providers': true },
        { 'Public Business Identity Providers': true },
        { 'Advanced RBAC': true },
        { 'On-Prem Identity providers': true },
      ],
    },
    {
      header: 'Collaboration',
      content: [
        { 'Advanced History': true },
        { 'Change Reviews': true },
        { 'Issue tracking': true },
      ],
    },
    {
      header: 'Advanced Features',
      content: [
        { 'Possibility of On-Prem dedicated setup': true },
        { 'Cloud Scan': true },
        { 'Cost Analysis': true },
        { 'Cost Predictions and Advice': true },
        { 'Cost Reduction': true },
      ],
    },
  ],
];
