import React from 'react';
import uniqid from 'uniqid';
import Carousel from '../Carousel';

const importAll = (result) => {
  let images = {};
  result.keys().forEach((item) => { images[item.replace(/.\/(.*)\.[^.]*$/, '$1')] = result(item); });
  return images;
}

const carouselImages = importAll(require.context('./images', false, /\.svg$/));

const carouselSettings = {
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 1000,
  vertical: true,
  verticalSwiping: true,
  centerMode: true,
  centerPadding: 20,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows:false
}

const SupportedComponents = () => {
  // after adding the image in the images folder, add the name below
  const {
    ambassador,
    elastic,
    firewall,
    gke,
    jaeger,
    kafka,
    keyVault,
    ocelot,
    postgres,
    privateCloud,
    prometheus,
    pubsub,
    scaleSets,
    secretManager,
    vm,
    vmg,
  } = carouselImages;

  const allColumns = [
    [ambassador, elastic, firewall, gke],
    [ocelot, jaeger, kafka, keyVault],
    [postgres, privateCloud, prometheus, pubsub],
    [scaleSets, secretManager, vm, vmg],
  ];

  return (
    <div className="vertical-carousel-wrapper vertical-carousel-wrapper--with-blur">
      {allColumns.map((column) => (
        <Carousel
          key={uniqid("slot-machine-")}
          className="vertical-carousel"
          settings={carouselSettings}
          autoplaySpeed={Math.random() * (4000 - 300) + 3000}
        >
          {column.map((image) => (
            <img
              key={uniqid("slot-machine-image-")}
              src={image.default}
              alt=""
              className="supported-component__image"
            />
          ))}
        </Carousel>
      ))}
    </div>
  );
}

export default SupportedComponents;
